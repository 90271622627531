import { loadCdnStyle, cdnBaseUrl } from 'o365.modules.helpers.js';
import FileUtils from 'o365.modules.fileUtils.ts';
import SpotLight from 'spotlight';

const {getExtensionFromFileName,getFileNameFromUrl,isImage} = FileUtils;
const supportedVideo = ['mp4','webm','ogg'];
const options = {
    fit: "contain",
    autohide: "false",
    index:0
    
}
export default {
    mounted(el) {
       loadCdnStyle('spotlight/src/css/spotlight.css');
 
       el.addEventListener('click', (e) => {

             if(e.target.closest("a")){
                const vLink = e.target.closest("a").href;
                let src = null;
                if(supportedVideo.indexOf(getExtensionFromFileName(getFileNameFromUrl(vLink))) > -1){
                    src = vLink;
                }

                if(isImage(getExtensionFromFileName(getFileNameFromUrl(vLink)))){
                    src = vLink;
                }
        
                if(!src) return;
                
                e.preventDefault();
            
                const vGalery = [];
                el.querySelectorAll("a").forEach(item=>{
                    const vExtension = getExtensionFromFileName(item.href);
                    if(supportedVideo.indexOf(vExtension) > -1){
                        vGalery.push({
                            'src':item.href, 
                            id:item.href,
                          //  title:getFileNameFromUrl(item.href),
                            media:"video",
                            "autoplay": true,
                            "muted": true,
                            "preload": false,
                            "controls": true,
                            "inline": false,
                            "poster": `${cdnBaseUrl}/icons/image.svg`,
                        
                        
                        })
                    }
                    if(isImage(vExtension)){
                        vGalery.push({
                            src:item.href, 
                            id:item.href,
                            title:getFileNameFromUrl(item.href),
                        })
                    }

               
 
                    
                });

                SpotLight.init();
                SpotLight.removeControl("download")
                SpotLight.addControl("download");
                options.index = vGalery.map(x=>x.id).indexOf(src)+1;
                SpotLight.show(vGalery,options);

            }
               
          
        });
    },
}